import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Form from "@components/form/form"

const ContactPage = ({ data }) => (
  <Layout>
    <Seo title="Contact Us" description={data.site.siteMetadata.description} />
    <div className="page-headline">
      <div className="container">
        <h1>Contact Us</h1>
      </div>
    </div>

    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 my-5">
                <h3 className="mb-2">We look forward to hearing from you.</h3>
                <h5 className="text-muted">
                  Send us a message and we'll get back to you soon!
                </h5>
                <ul className="list-unstyled contact-list lead">
                  <li className="address pl-2">
                    6625 Delmonico Drive
                    <br />
                    Colorado Springs, CO 80919
                  </li>
                  <li className="phone pl-2">
                    <a href="tel:719-359-5995">719-359-5995</a>
                  </li>
                  <li className="fax pl-2">
                    <a href="tel:719-593-9903">719-593-9903</a>
                  </li>
                  <li className="email pl-2">
                    <a href="mailto:info@itspecialists.net">
                      info@itspecialists.net
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-wrapper shadow-lg">
                  <h6 className="text-secondary text-uppercase text-center mb-2">
                    Contact Us
                  </h6>
                  <Form
                    organization="oyTg4C9bZ5"
                    instance="4dr2FkRYMF"
                    successUrl="/thank-you"
                  >
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="Name"
                      />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        name="Email address"
                        required
                      />
                      <label htmlFor="email">Email address</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="email"
                        name="Message"
                        required
                      ></textarea>
                      <label htmlFor="message">Your message</label>
                    </div>
                    <div className="form-group">
                      <div className="recaptcha" />
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid px-0" style={{ marginBottom: "-10px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.8317046926322!2d-104.83161568509514!3d38.92782397956595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87134e1615593c13%3A0xa6790ad47d8bd995!2sIT%20Specialists!5e0!3m2!1sen!2sph!4v1587694021759!5m2!1sen!2sph"
        width="100%"
        height="350"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        title="map"
      ></iframe>
    </div>
  </Layout>
)

export default ContactPage

export const contactPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
